.tournament {

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -7rem;
    padding-right: 7rem;
    padding-bottom: 10rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/tournament/bg@2x.png) 2x,
      url(../img/tournament/bg@1x.png) 1x
    );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      align-items: initial;
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__image {
    position: relative;
    margin-right: 2rem;
    max-width: 39rem;

    .image__text {
      width: 37rem;
      font-weight: 700;
      font-size: fluid-font(4.2rem, 1.8rem);
      transform: translate(-50%, -50%) rotate(-77deg);

      @media (max-width: em(992)) {
        width: 24rem;
        transform: translate(-50%, -50%) rotate(0);
      }

      @media (max-width: em(575)) {
        width: 17rem;
      }
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__content {
    max-width: 70rem;

    @media (max-width: em(1200)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }
}

