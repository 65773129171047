.resume {

  .container {
    overflow: hidden;
    padding-top: 10rem;

    @media (max-width: em(992)) {
      padding-top: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
    }
  }

  &__wrapper {
    z-index: 1;
    margin-left: -7rem;
    padding-right: 7rem;
    padding-bottom: 10rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/resume/bg@2x.png) 2x,
      url(../img/resume/bg@1x.png) 1x
    );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link {
  }
}

