.faq {
  &__wrapper {
  }

  &__title {
    margin-bottom: 3rem;
  }

  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: em(992)) {
      grid-gap: 2rem;
    }

    @media (max-width: em(767)) {
      display: block;
      grid-gap: initial;
    }
  }

  &__item {
    border: 0.1rem solid var(--clr-primary-300);
    border-radius: 1.5rem;
    padding: 5rem;
    background-color: var(--clr-primary-800);

    &:nth-child(4) {
      grid-column: 2 / 3;
      grid-row: 2 / 4;
    }

    &-title {
      margin-bottom: 2rem;
      font-size: fluid-font(2.4rem, 1.8rem);
      line-height: 150%;
    }

    &-text {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    @media (max-width: em(992)) {
      padding: 3rem;
    }

    @media (max-width: em(767)) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    @media (max-width: em(575)) {
      padding: 1.5rem;
    }
  }
}
