.poker {

  .container {
    background-color: var(--clr-primary-900);
  }

  &__wrapper {
  }

  &__content {
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
