/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Segoe UI", sans-serif;
  --font-weight: 400;
  --content-width: 122rem;
  --container-offset: 7rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: #fffaf3;
  --clr-default-300: rgba(255, 255, 255, 0.05);
  --clr-default-400: #292526;
  --clr-default-500: #1f1a1b;
  --clr-default-600: #161616;
  --clr-default-700: #fec882;
  --clr-default-800: #94897a;
  --clr-default-900: #000;
  --clr-primary-200: rgba(255, 255, 255, 0.66);
  --clr-primary-300: rgba(255, 250, 243, 0.15);
  --clr-primary-400: linear-gradient(180deg, #fec882 0%, #f98745 100%);
  --clr-primary-500: #E36D22;
  --clr-primary-600: #8f8f8f;
  --clr-primary-800: #161616;
  --clr-primary-900: #111111;
}

body {
  font-weight: var(--font-weight);
  font-size: 1.8rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-500);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  border-right: 0.1rem solid rgba(255, 255, 255, 0.15);
  border-left: 0.1rem solid rgba(255, 255, 255, 0.15);
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  background-color: var(--clr-default-400);
}

.container--top {
  border-top: 0.1rem solid rgba(255, 255, 255, 0.15);
  border-radius: 1.5rem 1.5rem 0 0;
}

.container--bottom {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
  border-radius: 0 0 1.5rem 1.5rem;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.content {
  padding-top: 10rem;
}

.title {
  font-weight: 600;
  font-size: clamp(
    2.6rem,
    2.1076923077rem + 1.5384615385vw,
    4.2rem
  );
  line-height: 114%;
  color: var(--clr-default-700);
}

.description {
  font-size: clamp(
    1.6rem,
    1.5384615385rem + 0.1923076923vw,
    1.8rem
  );
  line-height: 145%;
  color: var(--clr-default-200);
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 100%;
  min-height: 7rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-400);
  background-image: var(--clr-primary-400);
  transition: box-shadow var(--transition);
}

.main__link:hover {
  box-shadow: 1px 1px 25px rgba(249, 139, 73, 0.15);
  transition: box-shadow var(--transition);
}

.image__text {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    0.5076923077rem + 4.0384615385vw,
    6rem
  );
  text-align: center;
  -webkit-text-stroke: 0.2rem var(--clr-primary-500);
  text-transform: uppercase;
  color: var(--clr-default-100);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.image__text::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  width: 50rem;
  height: 32rem;
  background-color: var(--clr-primary-500);
  opacity: 0.77;
  filter: blur(14rem);
  transform: translate(-50%, -50%);
}

.image-border img {
  border: 2px solid var(--clr-default-700);
  border-radius: 0.5rem;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item:not(:last-child) {
  margin-right: 2rem;
}

.menu__item a {
  font-weight: 600;
  line-height: 111%;
  color: var(--clr-default-800);
  transition: color var(--transition);
}

.menu__item:hover a {
  color: var(--clr-default-700);
  transition: color var(--transition);
}

.logo {
  margin-right: 2rem;
  max-width: 18rem;
}

.burger {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 13rem;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  max-width: 122rem;
}

.header__link {
  margin-left: 2rem;
  min-width: 20.5rem;
}

.header .container {
  border-right: initial;
  border-left: initial;
  background-color: initial;
}

.footer {
  display: flex;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.footer .container {
  border: initial;
  background-color: initial;
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__menu {
  margin-right: 2rem;
}

.footer__list {
  display: flex;
  align-items: center;
}

.footer__item a {
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.5384615385rem + 0.1923076923vw,
    1.8rem
  );
  color: var(--clr-default-800);
  transition: color var(--transition);
}

.footer__item:not(:last-child) {
  margin-right: 4rem;
}

.footer__item:hover a {
  color: var(--clr-default-700);
  transition: color var(--transition);
}

.footer__btn-link {
  min-width: 20rem;
}

.hero__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero__content {
  max-width: 70rem;
}

.hero__title {
  margin-bottom: 2rem;
  font-size: clamp(
    2.6rem,
    1.8rem + 2.5vw,
    5.2rem
  );
}

.hero__text {
  margin-bottom: 5rem;
  color: var(--clr-default-200);
}

.hero__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.hero__link {
  min-width: 49rem;
}

.hero__image {
  position: absolute;
  right: 0;
  top: 3rem;
  width: 49rem;
  height: 64rem;
}

.hero__image .image__text {
  min-width: 44rem;
}

.hero__image .image__text::before {
  height: 25rem;
}

.info__table {
  overflow: auto;
  border-radius: 1.5rem;
  background-color: var(--clr-default-600);
}

.info__table table {
  overflow: hidden;
  border-collapse: collapse;
  border-radius: 1.5rem;
  min-width: 51rem;
}

.info__table table tr td {
  vertical-align: top;
  border-bottom: 0.1rem solid var(--clr-default-300);
  padding-top: 2rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  font-size: clamp(
    1.6rem,
    1.5384615385rem + 0.1923076923vw,
    1.8rem
  );
  line-height: 145%;
}

.info__table table tr td a {
  color: var(--clr-default-100);
}

.info__table table tr td:first-child {
  min-width: 28rem;
  background-color: var(--clr-default-900);
}

.benefits .container {
  padding-top: 10rem;
}

.benefits__wrapper {
  position: relative;
  z-index: 1;
  margin-left: -7rem;
  padding-right: 7rem;
  padding-bottom: 10rem;
  padding-left: 7rem;
  width: calc(100% + 14rem);
  background-image: -webkit-image-set(url(../img/benefits/bg@2x.png) 2x, url(../img/benefits/bg@1x.png) 1x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.benefits__title {
  margin-bottom: 3rem;
  text-align: center;
}

.benefits__list {
  display: flex;
  justify-content: space-between;
}

.benefits__item {
  min-width: 58rem;
}

.benefits__item:first-child {
  margin-right: 3rem;
}

.benefits__item-title {
  position: relative;
  margin-bottom: 3rem;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--clr-default-700);
}

.benefits__item-title::after {
  content: "";
  position: absolute;
  right: -34rem;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background-image: url(../img/benefits/title-decor.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.benefits__item-title--cons::after {
  content: "";
  position: absolute;
  right: -22rem;
  top: 50%;
  width: 75%;
  height: 0.2rem;
  background-image: url(../img/benefits/title-decor.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.benefits__item-item {
  position: relative;
  padding-left: 6.5rem;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.7384615385rem + 0.1923076923vw,
    2rem
  );
  line-height: 150%;
}

.benefits__item-item--pros::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: var(--clr-default-700);
  transform: translateY(-50%);
}

.benefits__item-item--pros::after {
  content: "";
  position: absolute;
  left: 1rem;
  top: 50%;
  width: 2.1rem;
  height: 1.75rem;
  background-image: url(../img/benefits/pros.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.benefits__item-item--cons::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: var(--clr-primary-600);
  transform: translateY(-50%);
}

.benefits__item-item--cons::after {
  content: "";
  position: absolute;
  left: 1.3rem;
  top: 50%;
  width: 1.4rem;
  height: 1.4rem;
  background-image: url(../img/benefits/cons.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.benefits__item-item:not(:last-child) {
  margin-bottom: 4rem;
}

.registration .container {
  overflow: hidden;
}

.registration__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.registration__content {
  max-width: 70rem;
}

.registration__title {
  margin-bottom: 2rem;
}

.registration__text {
  margin-bottom: 5rem;
}

.registration__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.registration__image {
  position: absolute;
  right: -26.5rem;
  top: 10rem;
  width: 74rem;
  height: 42rem;
}

.app__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app__image {
  position: relative;
  margin-right: 3rem;
  max-width: 38rem;
}

.app__image .image__text {
  width: 43rem;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.app__content {
  max-width: 80rem;
}

.app__content-item:not(:last-child) {
  margin-bottom: 10rem;
}

.app__title {
  margin-bottom: 2rem;
}

.app__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.bonus__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}

.bonus__info {
  margin-right: 3rem;
  max-width: 60rem;
}

.bonus__title {
  margin-bottom: 2rem;
}

.bonus__text {
  margin-bottom: 5rem;
}

.bonus__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.bonus__image {
  max-width: 59rem;
}

.bonus__image img {
  border-radius: 1.5rem;
}

.bonus__list {
  display: flex;
}

.bonus__item:not(:last-child) {
  margin-right: 3rem;
}

.bonus__item-title {
  margin-bottom: 1.5rem;
  font-size: clamp(
    1.8rem,
    1.7384615385rem + 0.1923076923vw,
    2rem
  );
}

.vip__title {
  margin-bottom: 2rem;
}

.vip__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.tournament__wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -7rem;
  padding-right: 7rem;
  padding-bottom: 10rem;
  padding-left: 7rem;
  width: calc(100% + 14rem);
  background-image: -webkit-image-set(url(../img/tournament/bg@2x.png) 2x, url(../img/tournament/bg@1x.png) 1x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tournament__image {
  position: relative;
  margin-right: 2rem;
  max-width: 39rem;
}

.tournament__image .image__text {
  width: 37rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.0615384615rem + 2.3076923077vw,
    4.2rem
  );
  transform: translate(-50%, -50%) rotate(-77deg);
}

.tournament__content {
  max-width: 70rem;
}

.tournament__title {
  margin-bottom: 2rem;
}

.tournament__text {
  margin-bottom: 5rem;
}

.slots__title {
  margin-bottom: 2rem;
}

.slots__text {
  margin-bottom: 3rem;
}

.slots__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.slots__table {
  overflow: auto;
  border-radius: 1.5rem;
  background-color: var(--clr-default-600);
}

.slots__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.slots__table table thead tr {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.11);
}

.slots__table table thead th {
  vertical-align: top;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 145%;
  text-align: start;
  color: var(--clr-primary-200);
}

.slots__table table thead th:first-child {
  padding-top: 4rem;
  padding-left: 4rem;
  width: 38rem;
  background-color: var(--clr-default-900);
}

.slots__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.11);
}

.slots__table table tbody td {
  vertical-align: top;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  line-height: 145%;
}

.slots__table table tbody td:first-child {
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.7384615385rem + 0.1923076923vw,
    2rem
  );
  line-height: 150%;
  color: var(--clr-default-100);
  background-color: var(--clr-default-900);
}

.games__wrapper {
  padding-bottom: 10rem;
}

.games__title {
  margin-bottom: 2rem;
}

.games__text {
  margin-bottom: 3rem;
}

.games__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.games__table {
  margin-bottom: 5rem;
}

.games__table table tbody td:first-child {
  color: var(--clr-default-700);
}

.live .container {
  background-color: var(--clr-primary-900);
}

.live__title {
  margin-bottom: 2rem;
}

.live__text:not(:last-child) {
  margin-bottom: 3rem;
}

.live__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.live__list {
  display: grid;
  grid-gap: 0.5rem 3rem;
  grid-template-columns: 1fr 1fr;
  list-style: disc;
  margin-bottom: 3rem;
  padding-left: 2.5rem;
}

.live__item {
  font-size: clamp(
    1.8rem,
    1.6153846154rem + 0.5769230769vw,
    2.4rem
  );
  line-height: 145%;
  color: var(--clr-default-700);
}

.live__image {
  position: relative;
  max-width: 136rem;
}

.live__image .image__text {
  width: 44rem;
}

.poker .container {
  background-color: var(--clr-primary-900);
}

.poker__title {
  margin-bottom: 2rem;
}

.poker__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.license .container {
  background-color: var(--clr-primary-900);
}

.license__wrapper {
  padding-bottom: 10rem;
}

.license__title {
  margin-bottom: 2rem;
}

.license__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__title {
  margin-bottom: 3rem;
}

.faq__list {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr;
}

.faq__item {
  border: 0.1rem solid var(--clr-primary-300);
  border-radius: 1.5rem;
  padding: 5rem;
  background-color: var(--clr-primary-800);
}

.faq__item:nth-child(4) {
  grid-column: 2/3;
  grid-row: 2/4;
}

.faq__item-title {
  margin-bottom: 2rem;
  font-size: clamp(
    1.8rem,
    1.6153846154rem + 0.5769230769vw,
    2.4rem
  );
  line-height: 150%;
}

.faq__item-text:not(:last-child) {
  margin-bottom: 2rem;
}

.payment__title {
  margin-bottom: 2rem;
}

.payment__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.payment__text--top {
  margin-bottom: 3rem;
}

.payment__text--bottom {
  margin-bottom: 5rem;
}

.payment__table {
  overflow: auto;
  margin-bottom: 3rem;
  border-radius: 1.5rem;
  background-color: var(--clr-primary-800);
}

.payment__table table {
  border-collapse: collapse;
  min-width: 100%;
}

.payment__table table thead tr {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.11);
}

.payment__table table thead th {
  vertical-align: top;
  padding-top: 4rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 145%;
  text-align: start;
  color: var(--clr-primary-200);
}

.payment__table table thead th:first-child {
  padding-top: 4rem;
  padding-left: 4rem;
  background-color: var(--clr-default-900);
}

.payment__table table tbody tr:not(:last-child) {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.11);
}

.payment__table table tbody td {
  vertical-align: top;
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  line-height: 145%;
}

.payment__table table tbody td:first-child {
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.7384615385rem + 0.1923076923vw,
    2rem
  );
  line-height: 150%;
  color: var(--clr-default-700);
  background-color: var(--clr-default-900);
}

.support__title {
  margin-bottom: 3rem;
}

.support__table table {
  min-width: 100%;
}

.support__table table tbody td:first-child {
  width: 32rem;
}

.resume .container {
  overflow: hidden;
  padding-top: 10rem;
}

.resume__wrapper {
  z-index: 1;
  margin-left: -7rem;
  padding-right: 7rem;
  padding-bottom: 10rem;
  padding-left: 7rem;
  width: calc(100% + 14rem);
  background-image: -webkit-image-set(url(../img/resume/bg@2x.png) 2x, url(../img/resume/bg@1x.png) 1x);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.resume__title {
  margin-bottom: 2rem;
}

.resume__text {
  margin-bottom: 5rem;
}

.resume__text p:not(:last-child) {
  margin-bottom: 2rem;
}

@media (max-width: 85em) {
  .container {
    border-top: initial;
    border-radius: initial;
  }
}

@media (max-width: 80em) {
  .hero__content {
    margin-right: 2rem;
    max-width: 55rem;
  }

  .hero__image {
    position: relative;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 49rem;
  }

  .registration__content {
    margin-right: 3rem;
    max-width: 50rem;
  }

  .registration__image {
    position: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    max-width: 74rem;
  }
}

@media (max-width: 75em) {
  .logo {
    max-width: 15rem;
  }

  .header__wrapper {
    margin-right: initial;
    margin-left: initial;
    max-width: initial;
  }

  .header {
    min-height: 10rem;
  }

  .footer__item:not(:last-child) {
    margin-right: 2rem;
  }

  .benefits__item {
    min-width: initial;
  }

  .benefits__item-title::after {
    display: none;
  }

  .app__content {
    max-width: 65rem;
  }

  .bonus__info {
    max-width: 50rem;
  }

  .tournament__content {
    max-width: 55rem;
  }

  .slots__table table thead th:first-child {
    min-width: 25rem;
  }
}

@media (max-width: 62em) {
  .content {
    padding-top: 8rem;
  }

  .main__link {
    min-height: 6rem;
  }

  .image__text::before {
    width: 30rem;
    height: 20rem;
  }

  .image__text {
    -webkit-text-stroke: 0.1rem var(--clr-primary-500);
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu__item a {
    color: var(--clr-default-100);
  }

  .menu__item a {
    font-size: 2rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__link {
    margin-right: 2rem;
    margin-left: auto;
    min-width: 17rem;
  }

  .header {
    min-height: 9rem;
  }

  .footer__wrapper {
    flex-direction: column;
  }

  .footer__logo {
    margin-bottom: 5rem;
  }

  .footer__menu {
    margin-right: initial;
    margin-bottom: 5rem;
  }

  .footer__item:not(:last-child) {
    margin-right: 3rem;
  }

  .hero__wrapper {
    flex-direction: column;
    align-items: initial;
  }

  .hero__content {
    margin-right: initial;
    margin-bottom: 5rem;
    max-width: initial;
  }

  .hero__link {
    min-width: 100%;
  }

  .hero__image {
    margin-right: auto;
    margin-left: auto;
  }

  .info__table table tr td:first-child {
    min-width: 23rem;
  }

  .benefits .container {
    padding-top: 8rem;
  }

  .benefits__wrapper {
    padding-bottom: 8rem;
  }

  .benefits__list {
    flex-direction: column;
  }

  .benefits__item:first-child {
    margin-right: initial;
  }

  .benefits__item:not(:last-child) {
    margin-bottom: 6rem;
  }

  .registration__wrapper {
    flex-direction: column;
    align-items: initial;
  }

  .registration__content {
    margin-right: initial;
    margin-bottom: 5rem;
    max-width: initial;
  }

  .registration__image {
    margin-right: auto;
    margin-left: auto;
  }

  .app__wrapper {
    flex-direction: column-reverse;
    align-items: initial;
  }

  .app__image .image__text {
    width: 27rem;
    transform: translate(-50%, -50%) rotate(0);
  }

  .app__image {
    margin-right: auto;
    margin-left: auto;
  }

  .app__content {
    margin-bottom: 5rem;
    max-width: initial;
  }

  .app__content-item:not(:last-child) {
    margin-bottom: 8rem;
  }

  .bonus__content {
    flex-direction: column;
    align-items: initial;
  }

  .bonus__info {
    margin-right: initial;
    margin-bottom: 5rem;
    max-width: initial;
  }

  .bonus__image {
    margin-right: auto;
    margin-left: auto;
  }

  .bonus__list {
    flex-direction: column;
  }

  .bonus__item:not(:last-child) {
    margin-right: initial;
    margin-bottom: 3rem;
  }

  .tournament__wrapper {
    flex-direction: column-reverse;
    align-items: initial;
    padding-bottom: 8rem;
  }

  .tournament__image .image__text {
    width: 24rem;
    transform: translate(-50%, -50%) rotate(0);
  }

  .tournament__image {
    margin-right: auto;
    margin-left: auto;
  }

  .tournament__content {
    margin-bottom: 5rem;
    max-width: initial;
  }

  .slots__table table thead th:first-child {
    padding: 2rem;
    min-width: 20rem;
  }

  .slots__table table thead th {
    padding: 2rem;
  }

  .slots__table table tbody td {
    padding: 2rem;
  }

  .games__wrapper {
    padding-bottom: 8rem;
  }

  .license__wrapper {
    padding-bottom: 8rem;
  }

  .faq__list {
    grid-gap: 2rem;
  }

  .faq__item {
    padding: 3rem;
  }

  .payment__table table thead th:first-child {
    padding-left: 2rem;
  }

  .payment__table table tbody td {
    padding: 2rem;
  }

  .resume .container {
    padding-top: 8rem;
  }

  .resume__wrapper {
    padding-bottom: 8rem;
  }
}

@media (max-width: 86.875em) {
  .container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .benefits__wrapper {
    margin-left: -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% + 3rem);
  }

  .tournament__wrapper {
    margin-left: -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% + 3rem);
  }

  .resume__wrapper {
    margin-left: -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% + 3rem);
  }
}

@media (max-width: 47.9375em) {
  .main__link {
    border-radius: 1rem;
    min-height: 5rem;
  }

  .image__text::before {
    width: 25rem;
    height: 15rem;
  }

  .footer__wrapper {
    align-items: initial;
  }

  .footer__logo {
    margin-bottom: 3rem;
  }

  .footer__menu {
    margin-bottom: 3rem;
  }

  .footer__list {
    flex-direction: column;
    align-items: initial;
  }

  .footer__item:not(:last-child) {
    margin-right: initial;
    margin-bottom: 2rem;
  }

  .hero__image .image__text {
    min-width: 23rem;
  }

  .info__table table tr td:first-child {
    min-width: 18rem;
  }

  .info__table table tr td {
    padding-left: 2rem;
  }

  .app__content-item:not(:last-child) {
    margin-bottom: 6rem;
  }

  .games__table table thead th:first-child {
    width: 20rem;
    min-width: initial;
  }

  .faq__list {
    display: block;
    grid-gap: initial;
  }

  .faq__item:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .content {
    padding-top: 5rem;
  }

  .image__text {
    -webkit-text-stroke: 0.05rem var(--clr-primary-500);
  }

  .header__link {
    margin-right: initial;
    margin-left: initial;
  }

  .footer__btn-link {
    min-width: 100%;
  }

  .footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .info__table table tr td {
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
  }

  .benefits .container {
    padding-top: 5rem;
  }

  .benefits__wrapper {
    padding-bottom: 5rem;
  }

  .benefits__item:not(:last-child) {
    margin-bottom: 4rem;
  }

  .benefits__item-title {
    margin-bottom: 2rem;
  }

  .benefits__item-item--pros::before {
    width: 3.2rem;
    height: 3.2rem;
  }

  .benefits__item-item--pros::after {
    left: 0.75rem;
    width: 1.7rem;
    height: 1.4rem;
  }

  .benefits__item-item--cons::before {
    width: 3.2rem;
    height: 3.2rem;
  }

  .benefits__item-item--cons::after {
    left: 0.85rem;
  }

  .benefits__item-item:not(:last-child) {
    margin-bottom: 3rem;
  }

  .benefits__item-item {
    padding-left: 4rem;
  }

  .registration__content {
    margin-bottom: 3rem;
  }

  .registration__text {
    margin-bottom: 3rem;
  }

  .app__content {
    margin-bottom: 3rem;
  }

  .app__content-item:not(:last-child) {
    margin-bottom: 3rem;
  }

  .bonus__content {
    margin-bottom: 3rem;
  }

  .bonus__info {
    margin-bottom: 3rem;
  }

  .bonus__text {
    margin-bottom: 3rem;
  }

  .tournament__wrapper {
    padding-bottom: 5rem;
  }

  .tournament__image .image__text {
    width: 17rem;
  }

  .tournament__content {
    margin-bottom: 3rem;
  }

  .tournament__text {
    margin-bottom: 3rem;
  }

  .games__wrapper {
    padding-bottom: 5rem;
  }

  .games__table table thead th:first-child {
    width: 13rem;
  }

  .games__table {
    margin-bottom: 3rem;
  }

  .live__list {
    display: block;
    grid-gap: initial;
  }

  .live__item:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .live__image .image__text {
    width: 20rem;
  }

  .live__image .image__text::before {
    filter: blur(5rem);
  }

  .license__wrapper {
    padding-bottom: 5rem;
  }

  .faq__item {
    padding: 1.5rem;
  }

  .resume .container {
    padding-top: 5rem;
  }

  .resume__wrapper {
    padding-bottom: 5rem;
  }
}