.app {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      align-items: initial;
    }
  }

  &__image {
    position: relative;
    margin-right: 3rem;
    max-width: 38rem;

    .image__text {
      width: 43rem;
      transform: translate(-50%, -50%) rotate(-90deg);

      @media (max-width: em(992)) {
        width: 27rem;
        transform: translate(-50%, -50%) rotate(0);
      }
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__content {
    max-width: 80rem;

    @media (max-width: em(1200)) {
      max-width: 65rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content-item {
    &:not(:last-child) {
      margin-bottom: 10rem;

      @media (max-width: em(992)) {
        margin-bottom: 8rem;
      }

      @media (max-width: em(767)) {
        margin-bottom: 6rem;
      }

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
