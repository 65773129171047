.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.content {
  padding-top: 10rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 5rem;
  }
}

.title {
  font-weight: 600;
  font-size: fluid-font(4.2rem, 2.6rem);
  line-height: 114%;
  color: var(--clr-default-700);
}

.description {
  font-size: fluid-font(1.8rem, 1.6rem);
  line-height: 145%;
  color: var(--clr-default-200);
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: max-content;
  min-width: 100%;
  min-height: 7rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: var(--clr-default-400);
  background-image: var(--clr-primary-400);
  transition: box-shadow var(--transition);

  @media (max-width: em(992)) {
    min-height: 6rem;
  }

  @media (max-width: em(767)) {
    border-radius: 1rem;
    min-height: 5rem;
  }

  &:hover {
    box-shadow: 1px 1px 25px rgba(249, 139, 73, 0.15);
    transition: box-shadow var(--transition);
  }
}

.image__text {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-weight: 600;
  font-size: fluid-font(6rem, 1.8rem);
  text-align: center;
  -webkit-text-stroke: 0.2rem var(--clr-primary-500);
  text-transform: uppercase;
  color: var(--clr-default-100);
  transform: translate(-50%, -50%);
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    width: 50rem;
    height: 32rem;
    background-color: var(--clr-primary-500);
    opacity: 0.77;
    filter: blur(14rem);
    transform: translate(-50%, -50%);

    @media (max-width: em(992)) {
      width: 30rem;
      height: 20rem;
    }

    @media (max-width: em(767)) {
      width: 25rem;
      height: 15rem;
    }
  }

  @media (max-width: em(992)) {
    -webkit-text-stroke: 0.1rem var(--clr-primary-500);
  }

  @media (max-width: em(575)) {
    -webkit-text-stroke: 0.05rem var(--clr-primary-500);
  }
}

.image-border {
  img {
    border: 2px solid var(--clr-default-700);
    border-radius: 0.5rem;
  }
}
