.support {
  &__title {
    margin-bottom: 3rem;
  }

  &__table {
    table {
      min-width: 100%;

      tbody {
        td {
          &:first-child {
            width: 32rem;
          }
        }
      }
    }
  }
}

