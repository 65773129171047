.license {

  .container {
    background-color: var(--clr-primary-900);
  }

  &__wrapper {
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }
  
    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

