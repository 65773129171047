.hero {

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__content {
    max-width: 70rem;

    @media (max-width: em(1280)) {
      margin-right: 2rem;
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial;
    }
  }

  &__title {
    margin-bottom: 2rem;
    font-size: fluid-font(5.2rem, 2.6rem);
  }

  &__text {
    margin-bottom: 5rem;
    color: var(--clr-default-200);

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link {
    min-width: 49rem;

    @media (max-width: em(992)) {
      min-width: 100%;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    top: 3rem;
    width: 49rem;
    height: 64rem;

    .image__text {
      min-width: 44rem;

      &::before {
        height: 25rem;
      }

      @media (max-width: em(767)) {
        min-width: 23rem;
      }
    }

    @media (max-width: em(1280)) {
      position: relative;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 49rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
