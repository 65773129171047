.benefits {
  .container {
    padding-top: 10rem;

    @media (max-width: em(992)) {
      padding-top: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    margin-left: -7rem;
    padding-right: 7rem;
    padding-bottom: 10rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/benefits/bg@2x.png) 2x,
      url(../img/benefits/bg@1x.png) 1x
    );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 3rem;
    text-align: center;
  }

  &__list {
    display: flex;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__item {
    min-width: 58rem;

    &:first-child {
      margin-right: 3rem;

      @media (max-width: em(992)) {
        margin-right: initial;
      }
    }

    &:not(:last-child) {
      @media (max-width: em(992)) {
        margin-bottom: 6rem;
      }

      @media (max-width: em(575)) {
        margin-bottom: 4rem;
      }
    }

    @media (max-width: em(1200)) {
      min-width: initial;
    }
  }

  &__item-title {
    position: relative;
    margin-bottom: 3rem;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--clr-default-700);

    &::after {
      content: "";
      position: absolute;
      right: -34rem;
      top: 50%;
      width: 100%;
      height: 0.2rem;
      background-image: url(../img/benefits/title-decor.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: em(1200)) {
        display: none;
      }
    }

    &--cons {
      &::after {
        content: "";
        position: absolute;
        right: -22rem;
        top: 50%;
        width: 75%;
        height: 0.2rem;
        background-image: url(../img/benefits/title-decor.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 2rem;
    }
  }

  &__item-list {
  }

  &__item-item {
    position: relative;
    padding-left: 6.5rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 150%;

    &--pros {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        background-color: var(--clr-default-700);
        transform: translateY(-50%);

        @media (max-width: em(575)) {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 1rem;
        top: 50%;
        width: 2.1rem;
        height: 1.75rem;
        background-image: url(../img/benefits/pros.svg);
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateY(-50%);

        @media (max-width: em(575)) {
          left: 0.75rem;
          width: 1.7rem;
          height: 1.4rem;
        }
      }
    }


    &--cons {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        background-color: var(--clr-primary-600);
        transform: translateY(-50%);

        @media (max-width: em(575)) {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 1.3rem;
        top: 50%;
        width: 1.4rem;
        height: 1.4rem;
        background-image: url(../img/benefits/cons.svg);
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);

        @media (max-width: em(575)) {
          left: 0.85rem;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 4rem;

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(575)) {
      padding-left: 4rem;
    }
  }
}
