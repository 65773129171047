.games {
  &__wrapper {
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__table {
    margin-bottom: 5rem;

    table {
      thead {
        th {
          &:first-child {
            @media (max-width: em(767)) {
              width: 20rem;
              min-width: initial;
            }

            @media (max-width: em(575)) {
              width: 13rem;
            }
          }
        }
      }
      tbody {
        td {
          &:first-child {
            color: var(--clr-default-700);
          }
        }
      }
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }
}
