.registration {

  .container {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__content {
    max-width: 70rem;

    @media (max-width: em(1280)) {
      margin-right: 3rem;
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }

  &__image {
    position: absolute;
    right: -26.5rem;
    top: 10rem;
    width: 74rem;
    height: 42rem;

    @media (max-width: em(1280)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
      max-width: 74rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
