.bonus {
  &__wrapper {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__info {
    margin-right: 3rem;
    max-width: 60rem;

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
  }

  &__image {
    max-width: 59rem;

    img {
      border-radius: 1.5rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__list {
    display: flex;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 3rem;

      @media (max-width: em(992)) {
        margin-right: initial;
        margin-bottom: 3rem;
      }
    }

    &-title {
      margin-bottom: 1.5rem;
      font-size: fluid-font(2rem, 1.8rem);
    }

    &-text {
    }
  }
}
